export const sygnet = [
  '160 160',
  `
  <title>coreui logo</title>
  <g>
    <g transform="matrix(2.69802,0,0,1.9423,-3380.57,-1421.59)">
        <g transform="matrix(0.654098,0,0,0.90892,770.828,-254.372)">
            <path d="M759.957,1087.94C748.912,1087.94 739.957,1096.9 739.957,1107.94C739.957,1118.99 748.912,1127.94 759.957,1127.94C771.002,1127.94 779.957,1118.99 779.957,1107.94C779.957,1096.9 771.002,1087.94 759.957,1087.94ZM759.957,1117.94C754.434,1117.94 749.957,1113.47 749.957,1107.94C749.957,1102.42 754.434,1097.94 759.957,1097.94C765.48,1097.94 769.957,1102.42 769.957,1107.94C769.957,1113.47 765.48,1117.94 759.957,1117.94Z" style="fill:white;"/>
        </g>
        <g transform="matrix(0.654098,0,0,0.90892,770.828,-254.372)">
            <path d="M824.954,1107.94L824.954,1122.94C824.954,1125.71 822.716,1127.94 819.954,1127.94C817.192,1127.94 814.954,1125.71 814.954,1122.94L814.954,1107.94C814.954,1102.42 810.477,1097.94 804.954,1097.94C799.431,1097.94 794.954,1102.42 794.954,1107.94L794.954,1122.94C794.954,1125.71 792.716,1127.94 789.954,1127.94C787.192,1127.94 784.954,1125.71 784.954,1122.94L784.954,1107.94C784.954,1096.9 793.909,1087.94 804.954,1087.94C815.999,1087.94 824.954,1096.9 824.954,1107.94Z" style="fill:white;"/>
        </g>
        <g transform="matrix(0.654098,0,0,0.90892,770.828,-254.372)">
            <path d="M789.957,1132.91C783.976,1132.91 778.622,1135.55 774.957,1139.71C771.292,1135.55 765.938,1132.91 759.957,1132.91C748.912,1132.91 739.957,1141.87 739.957,1152.91L739.957,1167.91C739.957,1170.67 742.195,1172.91 744.957,1172.91C747.719,1172.91 749.957,1170.67 749.957,1167.91L749.957,1152.91C749.957,1147.39 754.434,1142.91 759.957,1142.91C765.48,1142.91 769.957,1147.39 769.957,1152.91L769.957,1167.91C769.957,1170.67 772.195,1172.91 774.957,1172.91C777.719,1172.91 779.957,1170.67 779.957,1167.91L779.957,1152.91C779.957,1147.39 784.434,1142.91 789.957,1142.91C795.48,1142.91 799.957,1147.39 799.957,1152.91L799.957,1167.91C799.957,1170.67 802.195,1172.91 804.957,1172.91C807.719,1172.91 809.957,1170.67 809.957,1167.91L809.957,1152.91C809.957,1141.87 801.002,1132.91 789.957,1132.91ZM789.957,1134.05C800.377,1134.05 808.824,1142.49 808.824,1152.91L808.824,1167.91C808.824,1170.05 807.094,1171.78 804.957,1171.78C802.821,1171.78 801.091,1170.05 801.091,1167.91C801.091,1167.91 801.091,1152.91 801.091,1152.91C801.091,1146.76 796.106,1141.78 789.957,1141.78C783.809,1141.78 778.824,1146.76 778.824,1152.91L778.824,1167.91C778.824,1170.05 777.094,1171.78 774.957,1171.78C772.821,1171.78 771.091,1170.05 771.091,1167.91C771.091,1167.91 771.091,1152.91 771.091,1152.91C771.091,1146.76 766.106,1141.78 759.957,1141.78C753.809,1141.78 748.824,1146.76 748.824,1152.91L748.824,1167.91C748.824,1170.05 747.094,1171.78 744.957,1171.78C742.821,1171.78 741.091,1170.05 741.091,1167.91L741.091,1152.91C741.091,1142.49 749.538,1134.05 759.957,1134.05C765.6,1134.05 770.65,1136.54 774.107,1140.46C774.322,1140.71 774.632,1140.85 774.957,1140.85C775.283,1140.85 775.593,1140.71 775.808,1140.46C779.265,1136.54 784.315,1134.05 789.957,1134.05Z" style="fill:white;"/>
        </g>
        <g transform="matrix(0.654098,0,0,0.90892,770.828,-254.372)">
            <path d="M814.954,1167.91C814.954,1170.67 817.192,1172.91 819.954,1172.91C822.716,1172.91 824.954,1170.67 824.954,1167.91L824.954,1137.91C824.954,1135.15 822.716,1132.91 819.954,1132.91C817.192,1132.91 814.954,1135.15 814.954,1137.91L814.954,1167.91ZM816.088,1167.91L816.088,1137.91C816.088,1135.78 817.818,1134.05 819.954,1134.05C822.09,1134.05 823.821,1135.78 823.821,1137.91C823.821,1137.91 823.821,1167.91 823.821,1167.91C823.821,1170.05 822.09,1171.78 819.954,1171.78C817.818,1171.78 816.088,1170.05 816.088,1167.91Z" style="fill:white;"/>
        </g>
    </g>

  </g>
`,
]
