import React, { useEffect, useState } from 'react'
import app from './base.js'
import PropTypes from 'prop-types'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      setPending(false)
      // console.log('user:', user)
    })
  }, [])

  if (pending) {
    return (
      <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
      </div>
    )
  }

  return <AuthContext.Provider value={{ currentUser }}> {children} </AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}
