/* eslint-disable prettier/prettier */
import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { AuthProvider } from './auth/Auth'
import PrivateRoute from './auth/PrivateRoute'
import history from './auth/history'
import { ApolloProvider } from '@apollo/client'
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from '@apollo/client'
import { MyStore } from './reusable/Store'
import firebase from 'firebase/compat/app'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./auth/Login'))
const Logout = React.lazy(() => import('./auth/Logout'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {

  //get current Tenant from MyStore and intialise link for query
  let MyStoreTenant = MyStore((state) => state.tenantURL)
  const httpLink = new HttpLink({ uri: MyStoreTenant })

  const authMiddleware = new ApolloLink(async (operation, forward) => {
    const user = await firebase.auth().currentUser.getIdTokenResult()
    console.log('firebase', user.token)
    // console.log('apollo uri:', MyStoreTenant)
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-auth-token': user.token,
      },
    }))

    return forward(operation)
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
  })

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
          <Router history={history}>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/logout"
                  name="Test Logout Page"
                  render={(props) => <Logout {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <PrivateRoute path="/" name="Home" component={DefaultLayout} />
                {/* <PrivateRoute path="/" name="Home" render={(props) => <DefaultLayout {...props} />} /> */}
              </Switch>
            </React.Suspense>
          </Router>
      </ApolloProvider>
    </AuthProvider>
  )
}

export default App
