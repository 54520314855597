import create from 'zustand'

export const MyStore = create((set) => ({
  tenantURL: process.env.REACT_APP_DATABASE_URI,
  // tenant: { value: process.env.REACT_APP_PUBLIC_DATABASE_URI, label: 'Dev' },
  tenantinfo: {
    label: 'temp',
    label2: 'temp',
    privacy: 'temp',
    selectlabel: ['temp'],
    selectvalue: ['temp'],
    terms: 'temp',
    value: 'temp',
  },
  labels: ['temp'],
  labellist: {},
  language: 'EN',

  setTenant: (input) => set((state) => ({ tenantURL: input })),
  setTenantInfo: (input) => set((state) => ({ tenantinfo: input })),
  setLabels: (input) => set((state) => ({ labels: input })),
  setLabelList: (input) => set((state) => ({ labellist: input })),
  setLanguage: (input) => set((state) => ({ language: input })),
}))
