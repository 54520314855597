export const logoNegative = [
  '608 134',
  `
  <title>onmi logo</title>
  <g>
    <g transform="matrix(3.05116,0,0,3.05116,142.589,43.0834)">
        <path d="M0,15.677C-4.329,15.677 -7.838,12.168 -7.838,7.839C-7.838,3.51 -4.329,0.002 0,0.002C4.331,0.002 7.836,3.51 7.836,7.839C7.836,12.168 4.331,15.677 0,15.677ZM0,-7.836C-8.656,-7.836 -15.675,-0.817 -15.675,7.839C-15.675,16.496 -8.656,23.513 0,23.513C8.653,23.513 15.673,16.496 15.673,7.839C15.673,-0.817 8.653,-7.836 0,-7.836Z" style="fill:white;fill-rule:nonzero;"/>
    </g>
    <g transform="matrix(3.05116,0,0,3.05116,298.024,67.0005)">
        <path d="M0,0L0,11.757C0,13.922 -1.757,15.675 -3.918,15.675C-6.084,15.675 -7.836,13.922 -7.836,11.757L-7.836,0C-7.836,-4.329 -11.346,-7.836 -15.675,-7.836C-20.006,-7.836 -23.511,-4.329 -23.511,0L-23.511,11.757C-23.511,13.922 -25.268,15.675 -27.429,15.675C-29.595,15.675 -31.352,13.922 -31.352,11.757L-31.352,0C-31.352,-8.656 -24.333,-15.675 -15.675,-15.675C-7.019,-15.675 0,-8.656 0,0Z" style="fill:white;fill-rule:nonzero;"/>
    </g>
    <g transform="matrix(3.05116,0,0,3.05116,429.535,114.828)">
        <path d="M0,-31.35C-4.684,-31.35 -8.88,-29.279 -11.755,-26.018C-14.625,-29.279 -18.821,-31.35 -23.509,-31.35C-32.165,-31.35 -39.184,-24.331 -39.184,-15.673L-39.184,-3.918C-39.184,-1.753 -37.431,0 -35.266,0C-33.1,0 -31.348,-1.753 -31.348,-3.918L-31.348,-15.673C-31.348,-20.004 -27.838,-23.511 -23.509,-23.511C-19.183,-23.511 -15.673,-20.004 -15.673,-15.673L-15.673,-3.918C-15.673,-1.753 -13.92,0 -11.755,0C-9.589,0 -7.836,-1.753 -7.836,-3.918L-7.836,-15.673C-7.836,-20.004 -4.327,-23.511 0,-23.511C4.331,-23.511 7.838,-20.004 7.838,-15.673L7.838,-3.918C7.838,-1.753 9.593,0 11.757,0C13.922,0 15.675,-1.753 15.675,-3.918L15.675,-15.673C15.675,-24.331 8.66,-31.35 0,-31.35" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.66px;"/>
    </g>
    <g transform="matrix(3.05116,0,0,3.05116,489.329,31.1268)">
        <path d="M0,23.514C0,25.679 1.753,27.432 3.918,27.432C6.08,27.432 7.836,25.679 7.836,23.514L7.836,0.001C7.836,-2.16 6.08,-3.918 3.918,-3.918C1.753,-3.918 0,-2.16 0,0.001L0,23.514ZM0.655,23.514L0.655,0.001C0.655,-1.799 2.115,-3.263 3.918,-3.263C5.718,-3.263 7.181,-1.799 7.181,0.001L7.181,23.514C7.181,25.317 5.718,26.777 3.918,26.777C2.115,26.777 0.655,25.317 0.655,23.514Z" style="fill:white;"/>
    </g>
  </g>
`,
]
