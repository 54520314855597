export const logo = [
  '608 134',
  `
  <title>onmi</title>
  <g>
    <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <g transform="matrix(0.608842,0,0,0.608842,118.278,-58.2675)">
            <g transform="matrix(4.16667,0,0,4.16667,59.9271,169.428)">
                <path d="M0,17.433C-4.813,17.433 -8.717,13.53 -8.717,8.717C-8.717,3.903 -4.813,0.002 0,0.002C4.816,0.002 8.714,3.903 8.714,8.717C8.714,13.53 4.816,17.433 0,17.433ZM0,-8.714C-9.625,-8.714 -17.431,-0.909 -17.431,8.717C-17.431,18.344 -9.625,26.147 0,26.147C9.623,26.147 17.429,18.344 17.429,8.717C17.429,-0.909 9.623,-8.714 0,-8.714Z" style="fill:none;fill-rule:nonzero;stroke:black;stroke-width:1.64px;"/>
            </g>
            <g transform="matrix(4.16667,0,0,4.16667,295.966,205.747)">
                <path d="M0,0L0,13.073C0,15.481 -1.954,17.431 -4.357,17.431C-6.766,17.431 -8.715,15.481 -8.715,13.073L-8.715,0C-8.715,-4.814 -12.617,-8.714 -17.431,-8.714C-22.247,-8.714 -26.146,-4.814 -26.146,0L-26.146,13.073C-26.146,15.481 -28.099,17.431 -30.502,17.431C-32.91,17.431 -34.864,15.481 -34.864,13.073L-34.864,0C-34.864,-9.625 -27.059,-17.431 -17.431,-17.431C-7.806,-17.431 0,-9.625 0,0Z" style="fill:none;fill-rule:nonzero;stroke:black;stroke-width:1.64px;"/>
            </g>
            <g transform="matrix(4.16667,0,0,4.16667,495.673,278.374)">
                <path d="M0,-34.861C-5.209,-34.861 -9.875,-32.558 -13.071,-28.932C-16.263,-32.558 -20.929,-34.861 -26.143,-34.861C-35.768,-34.861 -43.573,-27.056 -43.573,-17.428L-43.573,-4.357C-43.573,-1.949 -41.624,0 -39.216,0C-36.808,0 -34.858,-1.949 -34.858,-4.357L-34.858,-17.428C-34.858,-22.244 -30.956,-26.145 -26.143,-26.145C-21.331,-26.145 -17.428,-22.244 -17.428,-17.428L-17.428,-4.357C-17.428,-1.949 -15.479,0 -13.071,0C-10.663,0 -8.714,-1.949 -8.714,-4.357L-8.714,-17.428C-8.714,-22.244 -4.811,-26.145 0,-26.145C4.816,-26.145 8.717,-22.244 8.717,-17.428L8.717,-4.357C8.717,-1.949 10.668,0 13.074,0C15.482,0 17.431,-1.949 17.431,-4.357L17.431,-17.428C17.431,-27.056 9.63,-34.861 0,-34.861" style="fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(4.16667,0,0,4.16667,586.477,151.273)">
                <path d="M0,26.147C0,28.555 1.949,30.504 4.356,30.504C6.76,30.504 8.714,28.555 8.714,26.147L8.714,0C8.714,-2.403 6.76,-4.357 4.356,-4.357C1.949,-4.357 0,-2.403 0,0L0,26.147Z" style="fill-rule:nonzero;"/>
            </g>
        </g>
    </g>
  </g>
`,
]
